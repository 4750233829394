import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

// Definición de la interfaz para los PRODUCTOS de PLATAFORMA UNBLOCK
interface PlatformProduct {
  id: number;
  product: string;
  priceWithoutIva?: number;
  iva: number;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  constructor() { }
}

// Tipos de PRODUCTOS de PLATAFORMA UNBLOCK
const PLATFORM_PRODUCTS: PlatformProduct[] = [
  { id: 2, product: 'mkt-pro', priceWithoutIva: 120, iva: 1.21 },
  { id: 3, product: 'mkt-premium', priceWithoutIva: 360, iva: 1.21 },
  { id: 4, product: 'mkt-balance', iva: 1.21 },
  { id: 5, product: 'mkt-slots-upgrade-50', iva: 1.21 },
];

// 1. URLs de PRODUCCIÓN
const PRODUCTION_URLS = {
  api: 'https://panelapi.unblockthecity.com',
  apiv1: 'https://appv1.unblockthecity.com',
  cdn: 'https://cdn.unblockthecity.com',
};

// 2. URLs de STAGE
const STAGE_URLS = {
  api: 'https://devpanelapi.unblockthecity.com',
  apiv1: 'https://devpanelapi.unblockthecity.com',
  cdn: 'https://cdn.unblockthecity.com',
};

// 3. URLs de DESARROLLO
const DEV_URLS = {
  api: 'https://apipanel-develop.unblockthecity.com',
  apiv1: 'https://apipanel-develop.unblockthecity.com',
  cdn: 'https://cdn.unblockthecity.com',
};

//4. URLs de DESARROLLO (Local)
const DEV_LOCAL_URLS = {
  api: 'http://127.0.0.1:88',
  apiv1: 'https://appv1.unblockthecity.com',
  cdn: 'https://cdn.unblockthecity.com',
};

export const API_URL =  environment.local ? STAGE_URLS.api : (environment.production ? PRODUCTION_URLS.api : DEV_URLS.api);
export const API_URLV1 = environment.local ? STAGE_URLS.apiv1 : (environment.production ? PRODUCTION_URLS.apiv1 : DEV_URLS.apiv1);
export const CDN_URL = environment.local ? STAGE_URLS.cdn : (environment.production ? PRODUCTION_URLS.cdn : DEV_URLS.cdn);

export const PLATFORM_PRODUCT_TYPES = PLATFORM_PRODUCTS;
