// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  local: false,
  production: true,
  minBalance: 30,
  basicActivation: true,
  spainId: 67,
  planBasicId: 1,
  roleAdminId: 1,
  cupon: 'PROUPGRADE',
  tpvUrl : 'https://m-tpv-stage.unblockthecity.com',
  whatsapp : '34654187085'
};
