import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { PlanReviewComponent } from '../../v2/planes-reviews/plan-review/plan-review.component';
import { NotificationService } from '../../services/notification.service';
import { LocalService } from '../../services/local.service';
import { PromoService } from '../../services/promo.service';
import { AuthenticationService } from '../../services/authentication.service';
import { MenuService } from '../../services/menu.service';
import { User } from '../../models/user';
import { CartaRequest } from '../../interfaces/cartaRequest.model';
import { LocalRequest } from '../../interfaces/localRequest.model';
import { PromoRequest } from '../../interfaces/promoRequest.model';
import { Pagination } from '../../models/pagination';
import { Local } from '../../models/local';
import { Carta } from '../../models/carta';
import { Promo } from '../../models/promo';
import { Events } from '../../services/events.service';
import { PlanSpaceGoComponent } from '../../v2/planes/plan-space-go/plan-space-go.component';
import { PlanBasicInfoComponent } from '../../v2/planes/plan-basic-info/plan-basic-info.component';
import * as moment from 'moment';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss'],
})
export class FullComponent implements OnInit {
  public color = 'white';
  public showSettings = false;
  public showMinisidebar = false;
  public showDarktheme = false;
  public showRtl = false;
  public year: any;
  public user: User;
  public isAdmin = false;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private translate: TranslateService,
    private menuService: MenuService,
    private authenticationService: AuthenticationService,
    private localService: LocalService,
    private notificationService: NotificationService,
    private event: Events,
    private promoService: PromoService
  ) {
    this.year = new Date().getFullYear();
    this.event.subscribe('planReview', () => {
      this.planReview();
    });
  }

  ngOnInit() {
    this.user = this.authenticationService.currentUserValue.user;
    if (this.user.rolId === 1) {
      this.isAdmin = true;
    }

    if (this.user && this.user.rolId !== environment.roleAdminId) {
      if (this.user.rolId === 4 || this.user.rolId === 6) {
        if (this.user.wizard) {
          this.router.navigate(['/customers']);
        } else {
          const wizard = localStorage.getItem('ubwizardfinish');
          if (wizard && wizard === '1') {
            const modalRef = this.modalService.open(PlanSpaceGoComponent, {
              centered: true,
              windowClass: 'newModal',
              backdrop: 'static',
              keyboard: false,
            });
          } else {
            this.planReview();
          }
        }
      }
    }
  }

  redirectToWhatsApp() {
    this.authenticationService.currentUser.subscribe( (d) => {
      window.open('https://wa.me/' + d.configuracion.whatsappSupport, '_blank');
    })
    
  }

  public async planReview(): Promise<void> {
    const locales: Array<Local> = await this.checkLocal();
    const promosInApp: Array<Promo> = await this.checkPromoInApp();
    const promosQR: Array<Promo> = await this.checkPromoQR();


    // NO BORRAR, es temporal
    /*if (locales.length > this.user.plan.configuracion.localLimit ||
      promosInApp.length > this.user.plan.configuracion.promoLimitInApp ||
      promosQR.length > this.user.plan.configuracion.promoLimitQr
    ) {*/

    if (promosInApp.length > this.user.plan.configuracion.promoLimitInApp ||
      promosQR.length > this.user.plan.configuracion.promoLimitQr
    ) {
      const modalRef = this.modalService.open(PlanReviewComponent, {
        centered: true,
        windowClass: 'newModal plan-review',
        backdrop: 'static',
        keyboard: false,
      });
      modalRef.componentInstance.locales = locales;
      modalRef.componentInstance.promosInApp = promosInApp;
      modalRef.componentInstance.promosQR = promosQR;
      modalRef.result.then(
        (result: any) => {
          console.log(result);
        },
        (reason) => {
          console.log(reason);
        }
      );
    } else {
      if(this.user.plan.id === 1){
        if (environment.basicActivation) {
          let ask = true;
          if (localStorage.getItem('askBasicLimitations')) {
            const date = moment(localStorage.getItem('askBasicLimitations'));
            if (moment().diff(moment(date), 'days') < 1) {
              ask = false;
            }
          }

          if (ask) {
            const modalRef = this.modalService.open(PlanBasicInfoComponent, {
              centered: true,
              windowClass: 'newModal',
              backdrop: 'static',
              keyboard: false,
            });
          }
        }
      }
    }
  }

  public async checkLocal(): Promise<Local[]> {
    const localRequest: LocalRequest = new LocalRequest();

    localRequest.setActivo(true);
    localRequest.setClienteId(this.user.cliente ? this.user.cliente.id : null);
    return await this.localService
      .index(localRequest)
      .toPromise()
      .then(
        (pagination: Pagination) => {
          return pagination.data;
        },
        (error: HttpErrorResponse) => {
          this.notificationService.error(
            this.translate.instant(
              'Ocurrió un error al consultar los locales, vuelva a intentarlo. Si persiste pongase en contacto con desarrollo@unblockthecity.com y atenderemos su problema.'
            )
          );

          return Promise.resolve([]);
        }
      );
  }

  public async checkPromoInApp(): Promise<Promo[]> {
    const promoRequest: PromoRequest = new PromoRequest();

    promoRequest.setActiva(true);
    promoRequest.setClienteId(this.user.cliente ? this.user.cliente.id : null);
    promoRequest.setType(0);

    return await this.promoService
      .index(promoRequest)
      .toPromise()
      .then(
        (pagination: Pagination) => {
          return pagination.data;
        },
        (error: HttpErrorResponse) => {
          this.notificationService.error(
            this.translate.instant(
              'Ocurrió un error al consultar las promociones, vuelva a intentarlo. Si persiste pongase en contacto con desarrollo@unblockthecity.com y atenderemos su problema.'
            )
          );

          return [];
        }
      );
  }

  public async checkPromoQR(): Promise<Promo[]> {
    const promoRequest: PromoRequest = new PromoRequest();

    promoRequest.setActiva(true);
    promoRequest.setClienteId(this.user.cliente ? this.user.cliente.id : null);
    promoRequest.setType(1);

    return await this.promoService
      .index(promoRequest)
      .toPromise()
      .then(
        (pagination: Pagination) => {
          return Promise.resolve(pagination.data);
        },
        (error: HttpErrorResponse) => {
          this.notificationService.error(
            this.translate.instant(
              'Ocurrió un error al consultar las promociones, vuelva a intentarlo. Si persiste pongase en contacto con desarrollo@unblockthecity.com y atenderemos su problema.'
            )
          );

          return Promise.resolve([]);
        }
      );
  }
}
