import toastr from 'toastr';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';
import { Utils } from '../v2/utils/utils';

type ToastType = 'success' | 'warning' | 'error';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  optionsToast = {
    "closeButton": true,
    "debug": true,
    "newestOnTop": true,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "showDuration": "7000",
    "onclick" : null,
    "hideDuration": "10000",
    "timeOut": "5000",
    "extendedTimeOut": "4000",
    "showEasing": "linear",
    "hideEasing": "linear",
    "showMethod": "slideDown",
    "hideMethod": "slideUp"
  };

  constructor(
    private spinner: NgxSpinnerService,
    private user: AuthenticationService,
    private translate: TranslateService) {
  }

  public errorTranslate( message: string, originalError?: any, param?: any) {
    this.spinner.hide();

    this.showToast( 'error', this.translate.instant(message), param);

    console.error( originalError || message);

    return false;
  }

  private showToast( type: ToastType, message: string, param?: any) {
    this.spinner.hide();
    const toastFunc: Function = toastr[type].bind( toastr);

    if (param) {
      toastFunc(
        null,
        this.translate.instant(message) + param,
        { positionClass: 'toast-top-center' }
      );
    } else {
      toastFunc(
        null,
        this.translate.instant(message),
        { positionClass: 'toast-top-center' }
      );
    }
  }

  public error(msg,title?,duration?:number){
    this.spinner.hide();
    let data = msg;
    toastr.options = this.optionsToast;

    if ( title === undefined ){ title = 'Notificación de error'; }
    if ( duration !== undefined ){toastr.options.timeOut = duration}
    // Si contiene error es un error de la api
    if( msg !== undefined && ( msg.hasOwnProperty('error') || msg.hasOwnProperty('message') ) ){


      if( msg.error.message !== undefined ){ data = msg.error.message; }
      if( msg.message !== undefined ){ data = msg.message; }
          
      // Si eres superadmin puedes ver este error.
      this.user.currentUser.subscribe( (d) => {
        if( d !== null && d.hasOwnProperty('user') && d.user.rolId === 1 ){
          toastr.error( data, 'Mensaje para SUPERADMIN');
        }

      });

    } else {
      // Error para el cliente
      toastr.error( this.translate.instant(data), this.translate.instant(title));

    }

      

    
  }

  public info(msg,title?,duration?:number){
    this.spinner.hide();
    toastr.options = this.optionsToast;

    if ( title === undefined ){ title = 'Información'; }
    if ( duration !== undefined ){toastr.options.timeOut = duration}
    toastr.info( this.translate.instant(msg), this.translate.instant(title));
  }

  public success(msg,title?,duration?:number){
    this.spinner.hide();
    toastr.options = this.optionsToast;

    if ( title === undefined ){ title = 'Correcto!!'; }
    if ( duration !== undefined ){toastr.options.timeOut = duration}
    toastr.success( this.translate.instant(msg), this.translate.instant(title));
  }

  public warning(msg,title?,duration?:number){
    this.spinner.hide();
    toastr.options = this.optionsToast;

    if ( title === undefined ){ title = 'Correcto!!'; }
    if ( duration !== undefined ){toastr.options.timeOut = duration}
    toastr.warning( this.translate.instant(msg), this.translate.instant(title));
  }

  public versionCheck(){
    let data = `Existe una nueva versión disponible,vamos a recargar la nueva versión,
              si no lo hicera automáticamente recuerde que puede recargar su navegador con control + F5
              <br><button type="button" value="update" class="btn clear">Pulsa para actualizar</button> `;
    toastr.options = this.optionsToast;

    toastr.options.onclick = (d) => {
      if( d.target.value === 'update'){
        localStorage.clear()
        setTimeout( () => location.reload(),1500);
      }
    };

    let title = 'Actualización necesaria!';

    toastr.info( this.translate.instant(data), this.translate.instant(title));
  }

  public logger(title,...args){

    if ( !environment.production ){;
      console.log('%c LOGGER -> ',' font-weight: bold;background-color: #f02a3e;color: white;padding: 5px;border-radius: 10px;',title,args);
    }

  }
}
