import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as global from "../services/global.service";
import { objToCamelCase } from "../helpers/object.helper";
import { Pagination } from "../models/pagination";
import { Carta } from "../models/carta";
import { CartaRequest } from "../interfaces/cartaRequest.model";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  private static CARTAS_PATH = global.API_URL + "/api/cartas";
  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
    });
  }

  public index(cartaRequest: CartaRequest): Observable<Pagination> {
    let httpParams: HttpParams = new HttpParams();

    if (cartaRequest.getUserId())
      httpParams = httpParams.set("userId", String(cartaRequest.getUserId()));

    if (cartaRequest.getClienteId())
      httpParams = httpParams.set(
        "clienteId",
        String(cartaRequest.getClienteId())
      );

    if (typeof cartaRequest.getActiva() === "boolean")
      httpParams = httpParams.set("activa", String(cartaRequest.getActiva()));

    if (cartaRequest.getLocalId())
      httpParams = httpParams.set("localId", String(cartaRequest.getLocalId()));

    if (cartaRequest.getLocal())
      httpParams = httpParams.set("local", String(cartaRequest.getLocal()));

    if (cartaRequest.getZonaSubzonas())
      httpParams = httpParams.set(
        "zonaSubzonas",
        String(cartaRequest.getZonaSubzonas())
      );

    if (cartaRequest.getPerPage())
      httpParams = httpParams.set("perPage", String(cartaRequest.getPerPage()));

    if (cartaRequest.getOrderBy())
      httpParams = httpParams.set("orderBy", cartaRequest.getOrderBy());

    if (cartaRequest.getSortBy())
      httpParams = httpParams.set("sortBy", cartaRequest.getSortBy());

    if (cartaRequest.getPage())
    httpParams = httpParams.set("page", String(cartaRequest.getPage()));

    if (cartaRequest.getTipoCarta())
    httpParams = httpParams.set("tipoCarta", String(cartaRequest.getTipoCarta()));

    if (cartaRequest.getNombreCarta())
    httpParams = httpParams.set("nombreCarta", String(cartaRequest.getNombreCarta()));

    if (cartaRequest.getNombreEspacio())
    httpParams = httpParams.set("nombreEspacio", String(cartaRequest.getNombreEspacio()));

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .get<Pagination>(MenuService.CARTAS_PATH, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objToCamelCase<Pagination>(response);
        })
      );
  }

  public create(carta: Carta): Observable<Carta> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http.post<Carta>(
      MenuService.CARTAS_PATH,
      carta,
      this.httpOptions
    );
  }

  public byId(id: number): Observable<Carta> {

    this.httpOptions = {
      headers: this.httpHeaders,
    };

    return this.http
      .get<any>(MenuService.CARTAS_PATH + `/${id}`, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objToCamelCase(response);
        })
      );
  }

  public update(carta: Carta): Observable<Carta> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http.put<Carta>(
      MenuService.CARTAS_PATH + `/${carta.id}`,
      carta,
      this.httpOptions
    );
  }

  public fillData(carta: Carta): FormData {
    const formData: FormData = new FormData();

    if (carta.id) {
      formData.append("id", String(carta.id));
    }

    if (carta.activa !== null) formData.append("activa", String(carta.activa));

    if (carta.nombre) formData.append("nombre", String(carta.nombre));

    if (carta.descripcion)
      formData.append("descripcion", String(carta.descripcion));

    if (carta.imagen) formData.append("imagen", String(carta.imagen));

    if (carta.localId) formData.append("localId", String(carta.localId));

    if (carta.tipo != null) formData.append("tipo", String(carta.tipo));

    if (carta.urlpdf) formData.append("urlpdf", String(carta.urlpdf));

    if (carta.clienteId) formData.append("clienteId", String(carta.clienteId));

    if (carta.cartalink) formData.append("cartalink", String(carta.cartalink));

    if (carta.precio) formData.append("precio", String(carta.precio));

    if (carta.subtitulo) formData.append("subtitulo", String(carta.subtitulo));

    if (carta.moneda) formData.append("moneda", String(carta.moneda));

    return formData;
  }

  public transferLocal(carta: Carta): Observable<Carta> {
    this.httpOptions = {
      headers: this.httpHeaders.delete("Content-Type"),
      params: {},
    };

    return this.http.post<Carta>(
      MenuService.CARTAS_PATH + `/copiarcarta/${carta.id}`,
      this.fillData(carta),
      this.httpOptions
    );
  }

  public duplicate(carta: Carta): Observable<Carta> {
    this.httpOptions = {
      headers: this.httpHeaders.delete("Content-Type"),
      params: {},
    };

    return this.http.post<Carta>(
      MenuService.CARTAS_PATH + `/${carta.id}/duplicate`,
      carta,
      this.httpOptions
    );
  }

  public duplicateTransfer(carta: Carta): Observable<Carta> {
    this.httpOptions = {
      headers: this.httpHeaders.delete("Content-Type"),
      params: {},
    };

    return this.http.post<Carta>(
      MenuService.CARTAS_PATH + `/${carta.id}/duplicatetransfer`,
      carta,
      this.httpOptions
    );
  }

  public desactivar(): Observable<any> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http.put<any>(
      MenuService.CARTAS_PATH + "/desactivar",
      {},
      this.httpOptions
    );
  }

  public delete(carta: Carta): Observable<any> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http.delete<any>(
      MenuService.CARTAS_PATH + `/${carta.id}`,
      this.httpOptions
    );
  }
}
