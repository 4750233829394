import { PaginationRequest } from './paginationRequest.model';

export class QrRequest extends PaginationRequest {

  private localId: number;
  private zonaId: number;
  private subzonaId: number;
  private administracionId: number;
  private tipoQr: number;

  constructor() {
    super();
  }

  /**
   * 
   */
  public getLocalId(): number {
    return this.localId;
  }

  /**
   * 
   */
  public setLocalId(localId: number): void {
    this.localId = localId;
  }

  /**
   * 
   */
  public getZonaId(): number {
    return this.zonaId;
  }

  /**
   * 
   */
  public setZonaId(zonaId: number): void {
    this.zonaId = zonaId;
  }

  /**
   * 
   */
  public getSubZonaId(): number {
    return this.subzonaId;
  }

  /**
   * 
   */
  public setSubZonaId(subzonaId: number): void {
    this.subzonaId = subzonaId;
  }

  /**
   * 
   */
  public getAdministracionId(): number {
    return this.administracionId;
  }

  /**
   * 
   */
  public setAdministracionId(administracionId: number): void {
    this.administracionId = administracionId;
  }

    /**
   * 
   */
     public setTipoQr(tipoQr: number): void {
      this.tipoQr = tipoQr;
    }
  
    /**
     * 
     */
     public getTipoQr(): number {
      return this.tipoQr;
    }
  

}